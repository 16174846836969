import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"

import Container from "../../components/Container/Container"
import Product from "../../components/Product/Product"
import ProductHeader from "../../components/Product/ProductHeader"
import ProductDescription from "../../components/Product/ProductDescription"
import ProductDocs from "../../components/Product/ProductDocs"
import ProductGuide from "../../components/Product/ProductGuide"

import ProductDocsList from "../../components/Product/ProductDocsList"
import ProductAccordionListItem from "../../components/Product/ProductAccordion/ProductAccordionListItem"

import schematGphe from "../../images/products/gphe/schemat-gphe.png"
import gphePlyta from "../../images/products/gphe/gphe-plyta.png"

const WymiennikiGphe = () => {
  const { gphe } = useStaticQuery(
    graphql`
      query {
        gphe: file(relativePath: { eq: "products/gphe.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 720) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `
  )

  return (
    <Layout pageInfo={{ pageName: "wymienniki-gphe" }}>
      <SEO title="Wymienniki ciepła skręcane uszczelkowe GPHE" />
      <Container>
        <Product>
          <ProductHeader headerImage={gphe.childImageSharp.fluid}>
            Płytowe wymienniki ciepła zawierają pakiet proﬁlowanych płyt z
            otworami dla przepływu dwóch cieczy, między którymi wymieniane jest
            ciepło. Odpowiednie wyprofilowanie płyt zwiększa powierzchnię
            biorącą udział w wymianie ciepła. Kompaktowa budowa wymiennika,
            wysoka efektywność i niespotykana niezawodność pozwalają obniżyć
            koszty inwestycyjne, operacyjne oraz odciążyć środowisko naturalne.
          </ProductHeader>
          <ProductDocs>
            <ProductDocsList title="Karty katalogowe">
              <ProductAccordionListItem linkTo="/gphe/gphe-broszura.pdf">
                Płytowe uszczelkowe wymienniki ciepła Alfa Laval - broszura
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/M3.pdf">
                Alfa Laval M3
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/M6.pdf">
                Alfa Laval M6
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/M10.pdf">
                Alfa Laval M10
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/M15.pdf">
                Alfa Laval M15
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/MA30.pdf">
                Alfa Laval MA30
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/MX25.pdf">
                Alfa Laval MX25
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/T2.pdf">
                Alfa Laval T2
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/T5.pdf">
                Alfa Laval T5
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/T6.pdf">
                Alfa Laval T6
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/T8.pdf">
                Alfa Laval T8
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/T10.pdf">
                Alfa Laval T10
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/T20.pdf">
                Alfa Laval T20
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/T25.pdf">
                Alfa Laval T25
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/T45.pdf">
                Alfa Laval T45
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/T50.pdf">
                Alfa Laval T50
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/TL3.pdf">
                Alfa Laval TL3
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/TL6.pdf">
                Alfa Laval TL6
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/TL10.pdf">
                Alfa Laval TL10
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/TL15.pdf">
                Alfa Laval TL15
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/TL35.pdf">
                Alfa Laval TL35
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/TS6.pdf">
                Alfa Laval TS6
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/TS20.pdf">
                Alfa Laval TS20
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/TS35.pdf">
                Alfa Laval TS35
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/gphe/TS50.pdf">
                Alfa Laval TS50
              </ProductAccordionListItem>
            </ProductDocsList>
            <ProductDocsList title="Instrukcje">
              <ProductAccordionListItem linkTo="/gphe/gphe-instrukcja-obslugi.pdf">
                Instrukcja obsługi
              </ProductAccordionListItem>
            </ProductDocsList>
            <ProductDocsList title="Deklaracje zgodności i atesty">
              <ProductAccordionListItem linkTo="/gphe/gphe-deklaracja-zgodnosci.pdf">
                Deklaracja zgodności
              </ProductAccordionListItem>
            </ProductDocsList>
          </ProductDocs>
          <ProductDescription>
            <h4 style={{ marginTop: "1rem" }}>
              Zasada działania płytowych uszczelkowych skręcanych wymienników
              ciepła
            </h4>
            <div className="product__description-flex">
              <p>
                W płytowym uszczelkowym skręcanym wymienniku ciepła powierzchnię
                wymiany ciepła tworzy pakiet profilowanych płyt z otworami,
                skręcanych ze sobą pomiędzy płytą czołową i dociskową. Pomiędzy
                płytami znajdują się uszczelki. Kształt wyprofilowanych płyt
                optymalizuje wymianę ciepła, przez wykorzystanie dużej, lecz
                zwartej powierzchni, przez którą wymieniane jest ciepło pomiędzy
                przepływającymi mediami.
              </p>
              <div className="product__description-imagebox">
                <img
                  src={schematGphe}
                  alt="Schemat płytowego uszczelkowego skręcanego wymiennika ciepła"
                />
              </div>
            </div>
            <div className="product__description-flex">
              <div className="product__description-imagebox">
                <img src={gphePlyta} alt="" />
              </div>
              <p style={{ alignSelf: "center" }}>
                Płyty wymiennika ciepła są wytłaczane w postaci wzoru typu
                „jodełka”. Kiedy płyty są odwrócone względem siebie o 180°,
                pomiędzy nimi tworzą się kanały pozwalające na turbulentny
                przepływ mediów, generowanie wysokich współczynników przenikania
                ciepła oraz warunków sprzyjających samoczyszczeniu wymiennika.
              </p>
            </div>
            <p>
              Płytowe uszczelkowe wymienniki ciepła mogą być zaprojektowane i
              skonfigurowane do różnorodnych zastosowań, począwszy od stosunkowo
              prostych zadań z niskimi wymaganiami do bardzo wymagających
              procesów z koniecznością spełnienia najwyższych wymagań
              dotyczących zarówno wydajności i dokumentacji.{" "}
            </p>
            <h4 style={{ marginTop: "1rem" }}>
              Zalety płytowych uszczelkowych skręcanych wymienników ciepła:
            </h4>
            <ul>
              <li>Wysoka efektywność energetyczna - niski koszt operacyjny</li>
              <li>
                Elastyczna konfiguracja - powierzchnia wymiany ciepła może być
                modyfikowana
              </li>
              <li>Łatwa instalacja - kompaktowa konstrukcja</li>
              <li>
                Dostosowany do przeprowadzenia prac serwisowych w każdych
                warunkach (wymiennik łatwy do otwierania w celu inspekcji,
                czyszczenia i mycia w systemie CIP)
              </li>
            </ul>
            <h4 style={{ marginTop: "1rem" }}>
              Zastosowania płytowych uszczelkowych skręcanych wymienników ciepła
            </h4>
            Elastyczna konfiguracja i szerokość gamy produktowej typoszeregu
            przemysłowych uszczelkowych płytowych wymienników ciepła Alfa Laval
            pozwala stosować je we wszystkich branżach przemysłowych i wielu
            różnych procesach.
            <br />
            Płytowe uszczelkowe wymienniki ciepła znajdują zastosowanie w
            procesach takich, jak:
            <ul>
              <li>ogrzewanie</li>
              <li>chłodzenie</li>
              <li>odzysk ciepła</li>
              <li>kondensacja</li>
              <li>odparowanie</li>
            </ul>
            <ProductGuide />
          </ProductDescription>
        </Product>
      </Container>
    </Layout>
  )
}

export default WymiennikiGphe
